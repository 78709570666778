<template>
    <div class="admin-home">
      <div>
        <left-menu />
      </div>
      <div class="wrap">
        <topBar />
        <div class="content">
          <div class="page">
            <router-view />
          </div>
          <div class="footer">
            <foot></foot>
          </div>
        </div>
        
      </div>
      
      <!-- <reporting-notice :showReportingNotice="showReportingNotice" :setShowReportingNotice="setShowReportingNotice" :setShowActivateBrand="setShowActivateBrand"/> -->
      <!-- <activate-brand :showActivateBrand="showActivateBrand" :setShowActivateBrand="setShowActivateBrand"/> -->
    </div>
  </template>
  
  <script setup>
  
    import { onMounted, ref, watch } from 'vue';
  
    import leftMenu from './leftMenu/index.vue';
    import topBar from '~/components/topBar.vue';
    // import reportingNotice from './reportingNotice.vue';
    // import activateBrand from './activateBrand.vue';
    import foot from './leftMenu/foot.vue';
    import { useUserInfoStore } from '~/storeData/index';
    const pageMessage=ref({
    names:['Brands','name'],
    title:'Your Brands',
    phrase:'Review and update your creator-facing details and logo for each brand'
  })
    const store = useUserInfoStore();
    const showReportingNotice = ref(false);
    const showActivateBrand = ref(false);
  
    const setShowActivateBrand = status => {
      showActivateBrand.value = status;
    }
  
    const setShowReportingNotice = status => {
      showReportingNotice.value = status;
    }
  
    const checkStatus = () => {
      const status = store.userInfo.status;
      if (status === 'SELLER_PENDING_AGREED_TO_NOTICE') {
        showReportingNotice.value = true;
      }
    }
  
    watch(store.userInfo, () => {
      checkStatus();
    })
  
    onMounted(() => {
      checkStatus()
    })
  
  </script>
  <style lang="less" scoped>
    .admin-home {
      display: flex;
      width: 100dvw;
      height: 100dvh;
      // overflow: hidden;
      // min-width: 1440px;
      overflow-x: scroll;
      overflow-y: scroll;
      background-color:#F3F2F7;;
      .wrap {
        flex: 1;
        display: flex;
        flex-direction: column;
        min-width: 1176px;
        background-color: #F3F2F7;
        .content {
          flex: 1;
          overflow: auto;
          .page{
            min-height: calc(100% - 72px) ;
          }
        }
      }
    }
  </style>
  